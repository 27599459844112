// import saveAs from 'file-saver'

export const downloadSupportService = async value => {
	try {
		const response = await fetch(process.env.REACT_APP_HYPNOVR_URL+'/account/assets?dl=true&f=' + value.link, {
			method: 'GET',
			headers: new Headers({
				Authorization: 'Bearer ' + value.token,
			}),
		})
		if (response.ok) {
			const parsedResponse = await response.json()
			window.open(parsedResponse.url);
        } else {
			throw new Error('An error occurred during support downloading')
		}
	} catch (error) {
		throw error
	}
}
