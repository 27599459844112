import {
	DELETE_HEADSETS,
	DELETE_HEADSETS_FAILURE,
	DELETE_HEADSETS_SUCCESS,
	FETCH_HEADSETS,
	FETCH_HEADSETS_FAILURE,
	FETCH_HEADSETS_SUCCESS,
} from '../actions'

import initialState from '../store/initialState'

export default (state = initialState.headsets, action) => {
	switch (action.type) {
		case FETCH_HEADSETS:
			return { ...state, deleteMessage: null }
		case FETCH_HEADSETS_SUCCESS:
			return { ...state, data: action.payload.data }
		case FETCH_HEADSETS_FAILURE:
			return { ...state, error: action.payload.error }
		case DELETE_HEADSETS:
			return { ...state, deleteMessage: null }
		case DELETE_HEADSETS_SUCCESS:
			return { ...state, data: { ...state.data, connectedActiveSessions: 0 }, deleteMessage: action.payload.data }
		case DELETE_HEADSETS_FAILURE:
			return { ...state, error: action.payload.error }
		default:
			return state
	}
}
