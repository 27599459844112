import { call, put, takeLatest } from 'redux-saga/effects'

import {
	DELETE_HEADSETS,
	deleteHeadsetsFailure,
	deleteHeadsetsSuccess,
	FETCH_HEADSETS,
	fetchHeadsetsFailure,
	fetchHeadsetsSuccess,
} from '../actions'
import { deleteHeadsetsService, fetchHeadsetsService, getUserTokenService } from '../services'

export function* watchFetchHeadsets() {
	yield takeLatest(FETCH_HEADSETS, fetchHeadsetsSaga)
}

export function* watchDeleteHeadsets() {
	yield takeLatest(DELETE_HEADSETS, deleteHeadsetsSaga)
}

function* fetchHeadsetsSaga() {
	try {
		const token = yield call(getUserTokenService)
		const data = yield call(fetchHeadsetsService, token)
		yield put(fetchHeadsetsSuccess(data))
	} catch (err) {
		yield put(fetchHeadsetsFailure(err))
	}
}

function* deleteHeadsetsSaga() {
	try {
		const token = yield call(getUserTokenService)
		const data = yield call(deleteHeadsetsService, token)
		yield put(deleteHeadsetsSuccess(data))
	} catch (err) {
		yield put(deleteHeadsetsFailure(err))
	}
}
