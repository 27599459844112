export default {
	subscriptions: {
		data: null,
		error: null,
	},
	profile: {
		data: null,
		error: null,
		message: null,
	},
	user: {
		isFetching: false,
		isAuthenticated: false,
		data: null,
		error: null,
		company: null,
	},
	summary: {
		data: null,
		error: null,
	},
	sessions: {
		data: {},
		error: null,
	},
	support: {
		data: {},
		error: null,
	},
	headsets: {
		deleteMessage: null,
		data: null,
		error: null,
	},
}
