import { call, put, takeLatest } from 'redux-saga/effects'

import {
	DOWNLOAD_SESSIONS,
	downloadSessionsFailure,
	downloadSessionsSuccess,
	FETCH_SESSIONS,
	fetchSessionsFailure,
	fetchSessionsSuccess,
} from '../actions'
import { downloadSessionsService, fetchSessionsService, getUserTokenService } from '../services'

export function* watchFetchSessions() {
	yield takeLatest(FETCH_SESSIONS, fetchSessionsSaga)
}

export function* watchDownloadSessions() {
	yield takeLatest(DOWNLOAD_SESSIONS, downloadSessionsSaga)
}

function* fetchSessionsSaga(action) {
	try {
		const token = yield call(getUserTokenService)
		const data = yield call(fetchSessionsService, { selectedInfos: action.payload.selectedInfos, token })
		yield put(fetchSessionsSuccess(data))
	} catch (err) {
		yield put(fetchSessionsFailure(err))
	}
}

function* downloadSessionsSaga(action) {
	try {
		const token = yield call(getUserTokenService)
		yield call(downloadSessionsService, { selectedInfos: action.payload.selectedInfos, token })
		yield put(downloadSessionsSuccess())
	} catch (err) {
		yield put(downloadSessionsFailure(err))
	}
}
