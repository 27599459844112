import {
	FETCH_PROFILE,
	FETCH_PROFILE_FAILURE,
	FETCH_PROFILE_SUCCESS,
	UPDATE_PROFILE,
	UPDATE_PROFILE_FAILURE,
	UPDATE_PROFILE_SUCCESS,
} from '../actions'

import initialState from '../store/initialState'

export default (state = initialState.profile, action) => {
	switch (action.type) {
		case FETCH_PROFILE:
			return { ...state, message: null }
		case UPDATE_PROFILE:
			return { ...state, message: null }
		case FETCH_PROFILE_SUCCESS:
			return { ...state, data: action.payload.data.profil }
		case FETCH_PROFILE_FAILURE:
			return { ...state, error: action.payload.error }
		case UPDATE_PROFILE_SUCCESS:
			return { ...state, data: action.payload.data.profil, message: action.payload.data.key }
		case UPDATE_PROFILE_FAILURE:
			return { ...state, error: action.payload.error }
		default:
			return state
	}
}
