export const DOWNLOAD_SUPPORT = 'downloadSupport'
export const DOWNLOAD_SUPPORT_SUCCESS = 'downloadSupportSuccess'
export const DOWNLOAD_SUPPORT_FAILURE = 'downloadSupportFailure'

export const downloadSupport = link => ({
	type: DOWNLOAD_SUPPORT,
	payload: {
		link,
	},
})

export const downloadSupportSuccess = data => ({
	type: DOWNLOAD_SUPPORT_SUCCESS,
	payload: {
		data,
	},
})

export const downloadSupportFailure = err => ({
	type: DOWNLOAD_SUPPORT_FAILURE,
	payload: {
		error: err && err.toString(),
	},
})
