import { combineReducers } from 'redux'

import subscriptionsReducers from './subscriptionsReducers'
import profileReducers from './profileReducers'
import userReducers from './userReducers'
import sessionsReducers from './sessionsReducers'
import summaryReducers from './summaryReducers'
import supportReducers from './supportReducers'
import headsetsReducers from './headsetsReducers'

export default combineReducers({
	subscription:subscriptionsReducers,
	profile: profileReducers,
	user:userReducers,
	sessions: sessionsReducers,
	summary: summaryReducers,
	support: supportReducers,
	headsets: headsetsReducers,
})
