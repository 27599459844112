import { call, put, takeLatest } from 'redux-saga/effects'

import { FETCH_SUBSCRIPTIONS, fetchSubscriptionsFailure, fetchSubscriptionsSuccess } from '../actions'
import { fetchSubscriptionsService, getUserTokenService } from '../services'

export function* watchFetchSubscriptions() {
	yield takeLatest(FETCH_SUBSCRIPTIONS, fetchSubscriptionsSaga)
}

function* fetchSubscriptionsSaga() {
	try {
		const token = yield call(getUserTokenService)
		const data = yield call(fetchSubscriptionsService, token)
		yield put(fetchSubscriptionsSuccess(data))
	} catch (err) {
		yield put(fetchSubscriptionsFailure(err))
	}
}
