export const FETCH_SUMMARY = 'fetchSummary'
export const FETCH_SUMMARY_SUCCESS = 'fetchSummarySuccess'
export const FETCH_SUMMARY_FAILURE = 'fetchSummaryFailure'

export const fetchSummary = () => ({
	type: FETCH_SUMMARY,
})

export const fetchSummarySuccess = data => ({
	type: FETCH_SUMMARY_SUCCESS,
	payload: {
		data,
	},
})

export const fetchSummaryFailure = err => ({
	type: FETCH_SUMMARY_FAILURE,
	payload: {
		error: err && err.toString(),
	},
})
