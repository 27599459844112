import { call, put, takeLatest } from 'redux-saga/effects'

import {
	DOWNLOAD_SUPPORT,
	downloadSupportSuccess,
	downloadSupportFailure
} from '../actions'
import { downloadSupportService, getUserTokenService } from '../services'

export function* watchDownloadSupport() {
	yield takeLatest(DOWNLOAD_SUPPORT, downloadSupportSaga)
}

function* downloadSupportSaga(action) {
	
	try {
		const token = yield call(getUserTokenService)
	
		yield call(downloadSupportService, { link: action.payload.link, token })
		yield put(downloadSupportSuccess())
	} catch (err) {
	
		yield put(downloadSupportFailure(err))
	}
}
