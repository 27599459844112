export const FETCH_SESSIONS = 'fetchSessions'
export const FETCH_SESSIONS_SUCCESS = 'fetchSessionsSuccess'
export const FETCH_SESSIONS_FAILURE = 'fetchSessionsFailure'
export const DOWNLOAD_SESSIONS = 'downloadSessions'
export const DOWNLOAD_SESSIONS_SUCCESS = 'downloadSessionsSuccess'
export const DOWNLOAD_SESSIONS_FAILURE = 'downloadSessionsFailure'

export const fetchSessions = selectedInfos => ({
	type: FETCH_SESSIONS,
	payload: {
		selectedInfos,
	},
})

export const fetchSessionsSuccess = data => ({
	type: FETCH_SESSIONS_SUCCESS,
	payload: {
		data,
	},
})

export const fetchSessionsFailure = err => ({
	type: FETCH_SESSIONS_FAILURE,
	payload: {
		error: err && err.toString(),
	},
})

export const downloadSessions = selectedInfos => ({
	type: DOWNLOAD_SESSIONS,
	payload: {
		selectedInfos,
	},
})

export const downloadSessionsSuccess =() => ({
	type: DOWNLOAD_SESSIONS_SUCCESS,
})

export const downloadSessionsFailure = err => ({
	type: DOWNLOAD_SESSIONS_FAILURE,
	payload: {
		error: err && err.toString(),
	},
})
