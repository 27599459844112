import React from 'react'
import Lottie from 'react-lottie'

import animationData from './animationData.json'

const AnimatedLoader = (props) => <Lottie {...props} />

AnimatedLoader.propTypes = {
	...Lottie.propTypes,
}

AnimatedLoader.defaultProps = {
	...Lottie.defaultProps,
	options: {
		...Lottie.defaultProps.options,
		animationData
	},
	width: '100%',
	height: 50,
}

export default AnimatedLoader
