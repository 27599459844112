import { fork } from 'redux-saga/effects'

import { watchFetchSubscriptions } from './subscriptionsSagas'
import { watchFetchProfile, watchUpdateProfile } from './profileSagas'
import { watchCheckToken, watchFetchUser, watchSignOut } from './userSagas'
import { watchFetchSessions, watchDownloadSessions } from './sessionsSagas'
import { watchFetchSummary } from './summarySagas'
import { watchDownloadSupport } from './supportSagas'
import { watchDeleteHeadsets, watchFetchHeadsets } from './headsetsSagas'

export default function*() {
	yield fork(watchFetchSubscriptions)
	yield fork(watchFetchProfile)
	yield fork(watchUpdateProfile)
	yield fork(watchFetchUser)
	yield fork(watchFetchSessions)	
	yield fork(watchDownloadSessions)
	yield fork(watchCheckToken)
	yield fork(watchSignOut)
	yield fork(watchFetchSummary)
	yield fork(watchDownloadSupport)
	yield fork(watchFetchHeadsets)
	yield fork(watchDeleteHeadsets)
}
