import saveAs from 'file-saver'

export const fetchSessionsService = async value => {
	try {
		const response = await fetch(process.env.REACT_APP_HYPNOVR_URL+'/account/sessions?daysSpan=' + value.selectedInfos.selectedPeriod + '&user=' + value.selectedInfos.selectedUser, {
			method: 'GET',
			headers: new Headers({
				Authorization: 'Bearer ' + value.token,
			}),
		})
		if (response.ok) {
			const result = await response.json()
			return result
		} else {
			throw new Error('An error occurred during sessions fetching')
		}
	} catch (error) {
		throw error
	}
}

export const downloadSessionsService = async value => {
	try {
		const response = await fetch(process.env.REACT_APP_HYPNOVR_URL+'/account/sessions/export?daysSpan=' 
														+ value.selectedInfos.selectedPeriod + 
														'&user=' + value.selectedInfos.selectedUser, {
			method: 'GET',
			headers: new Headers({
				Authorization: 'Bearer ' + value.token,
			}),
		})
		if (response.ok) {
            let blob = new Blob([await response.text()], { type: 'text/csv' })
            saveAs(blob, 'export_session_' + new Date().toLocaleDateString() + '-' + value.selectedDay + 'days.csv')
        } else {
			throw new Error('An error occurred during sessions downloading')
		}
	} catch (error) {
		throw error
	}
}
