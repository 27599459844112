export const fetchProfileService = async token => {
	try {
		const response = await fetch(process.env.REACT_APP_HYPNOVR_URL+'/account/user', {
			method: 'GET',
			headers: new Headers({
				Authorization: 'Bearer ' + token,
			}),
		})
		if (response.ok) {
			const result = await response.json()
			return result
		} else {
			throw new Error('An error occurred during profile fetching')
		}
	} catch (error) {
		throw error
	}
}

export const updateProfileService = async ({ token, data }) => {
	try {
		const response = await fetch(process.env.REACT_APP_HYPNOVR_URL+'/account/user', {
			method: 'PATCH',
			headers: new Headers({
				Authorization: 'Bearer ' + token,
			}),
			body: JSON.stringify({ profil: data }),
		})
		if (response.ok) {
			const result = await response.json()
			return result
		} else {
			throw new Error('An error occurred during profile fetching')
		}
	} catch (error) {
		throw error
	}
}
