import {
	DOWNLOAD_SESSIONS,
	DOWNLOAD_SESSIONS_FAILURE,
	DOWNLOAD_SESSIONS_SUCCESS,
	FETCH_SESSIONS,
	FETCH_SESSIONS_FAILURE,
	FETCH_SESSIONS_SUCCESS,
} from '../actions'

import initialState from '../store/initialState'

export default (state = initialState.sessions, action) => {
	switch (action.type) {
		case FETCH_SESSIONS:
			return { ...state }
		case FETCH_SESSIONS_SUCCESS:
			return { ...state, data: { ...state.data, [action.payload.data.timeSpan]: action.payload.data } }
		case FETCH_SESSIONS_FAILURE:
			return { ...state, error: action.payload.error }
		case DOWNLOAD_SESSIONS:
			return { ...state }
		case DOWNLOAD_SESSIONS_SUCCESS:
			return { ...state }
		case DOWNLOAD_SESSIONS_FAILURE:
			return { ...state, error: action.payload.error }
		default:
			return state
	}
}
