export const FETCH_HEADSETS = 'fetchHeadsets'
export const FETCH_HEADSETS_SUCCESS = 'fetchHeadsetsSuccess'
export const FETCH_HEADSETS_FAILURE = 'fetchHeadsetsFailure'
export const DELETE_HEADSETS = 'deleteHeadsets'
export const DELETE_HEADSETS_SUCCESS = 'deleteHeadsetsSuccess'
export const DELETE_HEADSETS_FAILURE = 'deleteHeadsetsFailure'

export const fetchHeadsets = () => ({
	type: FETCH_HEADSETS,
})

export const fetchHeadsetsSuccess = data => ({
	type: FETCH_HEADSETS_SUCCESS,
	payload: {
		data,
	},
})

export const fetchHeadsetsFailure = err => ({
	type: FETCH_HEADSETS_FAILURE,
	payload: {
		error: err && err.toString(),
	},
})

export const deleteHeadsets = () => ({
	type: DELETE_HEADSETS,
})

export const deleteHeadsetsSuccess = data => ({
	type: DELETE_HEADSETS_SUCCESS,
	payload: {
		data,
	},
})

export const deleteHeadsetsFailure = err => ({
	type: DELETE_HEADSETS_FAILURE,
	payload: {
		error: err && err.toString(),
	},
})
