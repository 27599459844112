export const fetchUserService = async data => {
	try {
		const response = await fetch(process.env.REACT_APP_HYPNOVR_URL + '/account/login', {
			method: 'POST',
			body: JSON.stringify({
				email: data.email,
				password: data.password,
			}),
		})
		if (response.ok) {
			const result = await response.json()
			return result
		} else if (response.status === 400) {
			const error = await response.json()
			throw new Error(error[0].message)
		} else {
			throw new Error('An error occurred during user fetching')
		}
	} catch (error) {
		throw error
	}
}

export const setUserTokenService = async data => {
	if (!data.company || data.company === '') {
		localStorage.setItem('company', data.firstName + ' ' + data.lastName)
	} else {
		localStorage.setItem('company', data.company)
	}
	localStorage.setItem('userToken', data.token)
}

export const getUserTokenService = async () => {
	return localStorage.getItem('userToken')
}

export const getUserCompanyService = async () => {
	return localStorage.getItem('company')
}

export const removeUserTokenService = async () => {
	localStorage.removeItem('userToken')
	localStorage.removeItem('company')
}
