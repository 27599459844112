export const FETCH_PROFILE = 'fetchProfile'
export const FETCH_PROFILE_SUCCESS = 'fetchProfileSuccess'
export const FETCH_PROFILE_FAILURE = 'fetchProfileFailure'
export const UPDATE_PROFILE = 'updateProfile'
export const UPDATE_PROFILE_SUCCESS = 'updateProfileSuccess'
export const UPDATE_PROFILE_FAILURE = 'updateProfileFailure'

export const fetchProfile = () => ({
	type: FETCH_PROFILE,
})

export const fetchProfileSuccess = data => ({
	type: FETCH_PROFILE_SUCCESS,
	payload: {
		data,
	},
})

export const fetchProfileFailure = err => ({
	type: FETCH_PROFILE_FAILURE,
	payload: {
		error: err && err.toString(),
	},
})

export const updateProfile = data => ({
	type: UPDATE_PROFILE,
	payload: {
		data,
	},
})

export const updateProfileSuccess = data => ({
	type: UPDATE_PROFILE_SUCCESS,
	payload: {
		data,
	},
})

export const updateProfileFailure = err => ({
	type: UPDATE_PROFILE_FAILURE,
	payload: {
		error: err && err.toString(),
	},
})
