import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Fetch from 'i18next-fetch-backend'

i18n.use(Fetch)
	.use(LanguageDetector)
	.init({
		debug: process.env.NODE_ENV === 'development',
		
		load: 'languageOnly',
		detection: {
			// order and from where user language should be detected
			order: ['querystring', 'path', 'navigator', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag', 'subdomain'],

			// keys or params to lookup language from
			lookupQuerystring: 'lng',
			lookupCookie: 'i18next',
			lookupLocalStorage: 'i18nextLng',
			lookupFromPathIndex: true,
			lookupFromSubdomainIndex: false,

			// cache user language on
			caches: ['localStorage', 'cookie'],
			// excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

			// optional expire and domain for set cookie
			cookieMinutes: 10,
			cookieDomain: 'localhost',

			// optional htmlTag with lang attribute, the default is:
			htmlTag: document.documentElement,

			// whitelist language
			whitelist: ['fr', 'en', 'de'],

			nonExplicitWhitelist: true,

			// fallback to a similar whitelist language
			// Example 1: Browser language is 'es'
			// if 'es' is not found in whitelist, first fallback to any whitelist language that starts with 'es-', then fallback to fallbackLng ('es' -> 'es-*' -> fallbackLng)
			// Example 2: Browser language is 'es-MX'
			// if 'es-MX' is not found in whitelist, first fallback to 'es', then fallback to 'es-*', then fallback to fallbackLng ('es-MX' -> 'es' -> 'es-*' -> fallbackLng)
			checkForSimilarInWhitelist: true,

			// optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
			cookieOptions: {path:'/'}
		},
		interpolation: {
			escapeValue: false
		},
		react: {
			wait: true,
        },

		languages: ['fr', 'en', 'de'],
		fallbackLng: ['fr','en', 'de'],
		country :['fr','en', 'de'],
		
		backend: {
			loadPath: 'locales/{{lng}}/{{lng}}.json'
		},
	})

export default i18n
