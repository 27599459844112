export const FETCH_SUBSCRIPTIONS = 'fetchSubscriptions'
export const FETCH_SUBSCRIPTIONS_SUCCESS = 'fetchSubscriptionsSuccess'
export const FETCH_SUBSCRIPTIONS_FAILURE = 'fetchSubscriptionsFailure'

export const fetchSubscriptions = () => ({
	type: FETCH_SUBSCRIPTIONS,
})

export const fetchSubscriptionsSuccess = data => ({
	type: FETCH_SUBSCRIPTIONS_SUCCESS,
	payload: {
		data,
	},
})

export const fetchSubscriptionsFailure = err => ({
	type: FETCH_SUBSCRIPTIONS_FAILURE,
	payload: {
		error: err && err.toString(),
	},
})
