export const fetchSubscriptionsService = async token => {
	try {
		const response = await fetch(process.env.REACT_APP_HYPNOVR_URL+'/account/invoices', {
			method: 'GET',
			headers: new Headers({
				Authorization: 'Bearer ' + token,
			}),
		})
		if (response.ok) {
			return await response.json()
		} else {
			throw new Error('An error occurred during subscriptions fetching')
		}
	} catch (error) {
		throw error
	}
}
