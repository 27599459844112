export const FETCH_USER_TOKEN = 'fetchUserToken'
export const FETCH_USER_TOKEN_SUCCESS = 'fetchUserTokenSuccess'
export const FETCH_USER_TOKEN_FAILURE = 'fetchUserTokenFailure'
export const CHECK_USER_TOKEN = 'checkUserToken'
export const CHECK_USER_TOKEN_SUCCESS = 'checkUserTokenSuccess'
export const CHECK_USER_TOKEN_FAILURE = 'checkUserTokenFailure'
export const SIGN_OUT_USER = 'signOutUser'
export const SIGN_OUT_USER_SUCCESS = 'signOutUserSuccess'
export const SIGN_OUT_USER_FAILURE = 'signOutUserFailure'

export const fetchUserToken = (email, password, history) => ({
	type: FETCH_USER_TOKEN,
	payload: { email, password, history },
})

export const fetchUserTokenSuccess = data => ({
	type: FETCH_USER_TOKEN_SUCCESS,
	payload: {
		data,
	},
})

export const fetchUserTokenFailure = err => ({
	type: FETCH_USER_TOKEN_FAILURE,
	payload: {
		error: err.message && err.message.toString(),
	},
})

export const checkUserToken = (history) => ({
	type: CHECK_USER_TOKEN,
	payload: { history },
})

export const checkUserTokenSuccess = company => ({
	type: CHECK_USER_TOKEN_SUCCESS,
	payload: {
		company,
	},
})

export const checkUserTokenFailure = err => ({
	type: CHECK_USER_TOKEN_FAILURE,
	payload: {
		error: err && err.toString(),
	},
})

export const signOutUser = () => ({
	type: SIGN_OUT_USER,
})

export const signOutUserSuccess = () => ({
	type: SIGN_OUT_USER_SUCCESS,
})

export const signOutUserFailure = err => ({
	type: SIGN_OUT_USER_FAILURE,
	payload: {
		error: err && err.toString(),
	},
})
