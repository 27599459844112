import {
	DOWNLOAD_SUPPORT,
	DOWNLOAD_SUPPORT_SUCCESS,
	DOWNLOAD_SUPPORT_FAILURE
} from '../actions'

import initialState from '../store/initialState'

export default (state = initialState.support, action) => {
	switch (action.type) {
		case DOWNLOAD_SUPPORT:
			return { ...state }
		case DOWNLOAD_SUPPORT_SUCCESS:
			return { ...state }
		case DOWNLOAD_SUPPORT_FAILURE:
			return { ...state, error: action.payload.error }
		default:
			return state
	}
}