import { call, put, takeLatest } from 'redux-saga/effects'
import {
	FETCH_PROFILE,
	fetchProfileFailure,
	fetchProfileSuccess,
	UPDATE_PROFILE,
	updateProfileFailure,
	updateProfileSuccess,
} from '../actions'
import { fetchProfileService, getUserTokenService, updateProfileService } from '../services'

export function* watchFetchProfile() {
	yield takeLatest(FETCH_PROFILE, fetchProfileSaga)
}

export function* watchUpdateProfile() {
	yield takeLatest(UPDATE_PROFILE, updateProfileSaga)
}

function* fetchProfileSaga() {
	try {
		const token = yield call(getUserTokenService)
		const data = yield call(fetchProfileService, token)
		yield put(fetchProfileSuccess(data))
	} catch (err) {
		yield put(fetchProfileFailure(err))
	}
}

function* updateProfileSaga(action) {
	try {
		const token = yield call(getUserTokenService)
		const data = yield call(updateProfileService, { data: action.payload.data, token })
		yield put(updateProfileSuccess(data))
	} catch (err) {
		yield put(updateProfileFailure(err))
	}
}
