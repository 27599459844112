import {
	CHECK_USER_TOKEN,
	CHECK_USER_TOKEN_FAILURE,
	CHECK_USER_TOKEN_SUCCESS,
	FETCH_USER_TOKEN,
	FETCH_USER_TOKEN_FAILURE,
	FETCH_USER_TOKEN_SUCCESS,
	SIGN_OUT_USER,
	SIGN_OUT_USER_FAILURE,
	SIGN_OUT_USER_SUCCESS,
} from '../actions'

import initialState from '../store/initialState'

export default (state = initialState.user, action) => {
	switch (action.type) {
		case FETCH_USER_TOKEN:
			return { ...state, isFetching: true }
		case FETCH_USER_TOKEN_SUCCESS:
			return { ...state, isFetching: false, error: null, data: action.payload.data }
		case FETCH_USER_TOKEN_FAILURE:
			return { ...state, isFetching: false, error: action.payload.error }
		case CHECK_USER_TOKEN:
			return { ...state }
		case CHECK_USER_TOKEN_SUCCESS:
			return { ...state, isAuthenticated: true, company: action.payload.company }
		case CHECK_USER_TOKEN_FAILURE:
			return { ...state, isAuthenticated: false }
		case SIGN_OUT_USER:
			return { ...state }
		case SIGN_OUT_USER_SUCCESS:
			return { ...state, isAuthenticated: false }
		case SIGN_OUT_USER_FAILURE:
			return { ...state, error: action.payload.error }
		default:
			return state
	}
}
