import { FETCH_SUMMARY, FETCH_SUMMARY_FAILURE, FETCH_SUMMARY_SUCCESS } from '../actions'

import initialState from '../store/initialState'

export default (state = initialState.summary, action) => {
	switch (action.type) {
		case FETCH_SUMMARY:
			return { ...state }
		case FETCH_SUMMARY_SUCCESS:
			return { ...state, data: action.payload.data }
		case FETCH_SUMMARY_FAILURE:
			return { ...state, error: action.payload.error }
		default:
			return state
	}
}
