import { call, put, takeLatest } from 'redux-saga/effects'

import {
	CHECK_USER_TOKEN,
	checkUserTokenFailure,
	checkUserTokenSuccess,
	FETCH_USER_TOKEN,
	fetchUserTokenFailure,
	fetchUserTokenSuccess,
	SIGN_OUT_USER,
	signOutUserFailure,
	signOutUserSuccess,
} from '../actions'

import {
	fetchUserService,
	getUserCompanyService,
	getUserTokenService,
	removeUserTokenService,
	setUserTokenService,
} from '../services'

export function* watchFetchUser() {
	yield takeLatest(FETCH_USER_TOKEN, fetchUserSaga)
}

export function* watchCheckToken() {
	yield takeLatest(CHECK_USER_TOKEN, checkTokenSaga)
}

export function* watchSignOut() {
	yield takeLatest(SIGN_OUT_USER, signOutSaga)
}

function* fetchUserSaga(action) {
	try {
		const { history } = action.payload
		const data = yield call(fetchUserService, { ...action.payload })
		const url = history.location.state ? history.location.state.from.pathname : '/'
		yield put(fetchUserTokenSuccess(data))
		yield call(setUserTokenService, data)

		const company = yield call(getUserCompanyService)
		yield put(checkUserTokenSuccess(company))

		history.push(url)
	} catch (err) {
		yield put(fetchUserTokenFailure(err))
	}
}

function* checkTokenSaga(action) {
	try {
		const { history } = action.payload
		const url = history.location.state ? history.location.state.from.pathname : '/'
		const token = yield call(getUserTokenService)
		const company = yield call(getUserCompanyService)
		if (token) {
			yield put(checkUserTokenSuccess(company))
			history.push(url)
		} else {
			yield put(checkUserTokenFailure('Token Not Found'))
		}
	} catch (err) {
		yield put(checkUserTokenFailure(err))
	}
}

function* signOutSaga() {
	try {
		yield call(removeUserTokenService)
		yield put(signOutUserSuccess())
	} catch (err) {
		yield put(signOutUserFailure(err))
	}
}
