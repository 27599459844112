import { FETCH_SUBSCRIPTIONS, FETCH_SUBSCRIPTIONS_FAILURE, FETCH_SUBSCRIPTIONS_SUCCESS } from '../actions'

import initialState from '../store/initialState'

export default (state = initialState.subscriptions, action) => {
	switch (action.type) {
		case FETCH_SUBSCRIPTIONS:
			return { ...state }
		case FETCH_SUBSCRIPTIONS_SUCCESS:
			return { ...state, data: action.payload.data }
		case FETCH_SUBSCRIPTIONS_FAILURE:
			return { ...state, error: action.payload.error }
		default:
			return state
	}
}
