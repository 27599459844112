import 'react-app-polyfill/ie11'
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'

import 'babel-polyfill'
import intl from 'intl'
import 'intl/locale-data/jsonp/fr.js'

import store from './store'
import i18n from './__locales__'

import AnimatedLoader from './components/loader/AnimatedLoader'

if (process.env.NODE_ENV === 'development') {
	console.info(`${process.env.REACT_APP_NAME}:${process.env.REACT_APP_VERSION}`)
}

if (!global.Intl) {
	global.Intl = intl
}

const startup = async () => {
	if (process.env.REACT_APP_MOCK === 'true') {
		await import('./__mocks__')
	}

	const { default: App } = await import('./components/app/App')
	render(
		<Provider store={store}>
			<App />
		</Provider>,
		document.getElementById('root')
	)
}

const TIMEOUT = 1500
const startTimer = Date.now()

i18n.on('initialized', () => {
	const endTimer = Date.now()
	if (endTimer >= startTimer + TIMEOUT) {
		startup()
	} else {
		setTimeout(startup, TIMEOUT - (endTimer - startTimer))
	}
})

render(<AnimatedLoader />, document.getElementById('root'))
