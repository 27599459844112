export const fetchHeadsetsService = async token => {
	try {
		const response = await fetch(process.env.REACT_APP_HYPNOVR_URL+'/account/devices', {
			method: 'GET',
			headers: new Headers({
				Authorization: 'Bearer ' + token,
			}),
		})
		if (response.ok) {
			const result = await response.json()
			return result
		} else {
			throw new Error('An error occurred during headsets fetching')
		}
	} catch (error) {
		throw error
	}
}

export const deleteHeadsetsService = async token => {
	try {
		const response = await fetch(process.env.REACT_APP_HYPNOVR_URL+'/account/devices', {
			method: 'DELETE',
			headers: new Headers({
				Authorization: 'Bearer ' + token,
			}),
		})
		if (response.ok) {
			const result = await response.json()
			return result
		} else {
			throw new Error('An error occurred during headsets fetching')
		}
	} catch (error) {
		throw error
	}
}
